<template>
  <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }">
    <div class="tickets-system-local__scheam-bubble" v-if="data"
          @mouseleave="$emit('mouseleave')"
          @mouseover="$emit('mouseover')"
          :style="{bottom: data.top + 'px', left: data.left + 'px', transform: 'translate(-50%, ' + (this.direction === 'top' ? '0%' : '100%') + ')'}">
      <div class="inner">   
        <!-- <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }"> -->
          <p class="heading-sector">{{ data.name }}</p>
        <!-- </router-link> -->
        <p v-if="data.sectorId !== 19650 && data.sectorId !== 19640 && data.sectorId !== 19594 && data.sectorId !== 19606" class="heading">Вид из сектора на площадку</p>
        <div v-if="data.sectorId !== 19650 && data.sectorId !== 19640 && data.sectorId !== 19594 && data.sectorId !== 19606" class="picture-sector">
          <img
            onerror="javascript:this.src='/resources/sectorsview/logoHCSochi.svg'" :src="'/resources/sectorsview/stadium/' + data.sectorId + '_1.jpg'" alt=""/>
        </div>
        <div class="list">
          <p v-if="data.sectorId === 19650 || data.sectorId === 19640">Гости наблюдают за хоккейным матчем, удобно расположившись на местах в центральном секторе арены. Помимо лучшего вида на площадку, нашим гостям предложено кейтеринговое обслуживание и высочайший уровень гостеприимства.</p>
          <p v-if="data.sectorId === 19594 || data.sectorId === 19606">VIP-ложа. Уютное пространство с отдельным входом, великолепным обзором, ресторанным обслуживанием и высоким качеством сервиса.
Количество: до 10 человек.
</p>
          <p class="list-heading">Осталось свободных мест: {{ data.freeSeats }}</p>
          <ul class="list-inner">
            <li class="list-item" :key="index" v-for="(price, index) in data.availablePrices">Стоимость: {{ price }} ₽</li>
          </ul>
        </div>
        <!-- <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }"> -->
          <button type="button" class="yellow-button">Выбрать билеты</button>
        <!-- </router-link> -->
      </div>  
    </div>
 </router-link>
</template>


<script>
// 12 line
// 
export default {
  name: 'SectorInfoLayer',
  data() {
    return {
      direction: 'top'
    }
  },
  props: {
    data: {
      type: Object
    },
    seasonIds: {
      type: Array
    },
  },
  mounted() {
    const bbox = this.$el.getBoundingClientRect();
    if (bbox.top <= 0) {
      this.direction = 'bottom';
    }
  }
}
</script>

<style scoped lang="scss" src="@/styles/SectorInfoLayer.scss">
</style>
