<template>
  
  <div class="subheader">
    <AuthorizationModal 
      v-if="isAuthorizationModalShown"
      @closeAuthorizationModal="closeAuthorizationModal" 
    />
    <ul class="subheader__list">
      <li class="subheader__list-item">
        <!-- <router-link :to="{ name: 'main' }"> -->
          <a class="subheader__list-item-link" href="/tickets">Билеты</a>  
        <!-- </router-link>   -->
      </li>
      <li class="subheader__list-item">
        <!-- <router-link :to="{ name: 'abonements' }"> -->
          <a class="subheader__list-item-link" href="/seasonticket">Абонементы</a>
        <!-- </router-link>   -->
      </li>
      <li class="subheader__list-item" @click="ordersHandler">
        <a class="subheader__list-item-link">Мои&#160;заказы</a>
      </li>
      <!-- <li class="subheader__list-item">
        <a>Карта точек продаж</a>
      </li>
      <li class="subheader__list-item">
        <a>VIP-ложи</a>
      </li>
      <li class="subheader__list-item">
        <a>Выездные матчи</a>
      </li> -->
    </ul>
    <ul v-if="this.userInfo && this.userInfo.userEmail" class="subheader__user">
      <li class="subheader__user-item"> 
        E-mail: {{ this.userInfo.userEmail }}
      </li>
      <li v-if="this.userInfo.activeBalance === 0 || !this.userInfo.activeBalance" class="subheader__user-item"> 
        Баланс: {{ this.userInfo.activeBalance }} бонусов
      </li>
      <li v-if="this.userInfo.activeBalance === 1" class="subheader__user-item"> 
        Баланс: {{ this.userInfo.activeBalance }} бонус
      </li>
      <li v-if="this.userInfo.activeBalance >= 2 && this.userInfo.activeBalance <= 5" class="subheader__user-item"> 
        Баланс: {{ this.userInfo.activeBalance }} бонуса
      </li>
      <li v-if="this.userInfo.activeBalance >= 6" class="subheader__user-item"> 
        Баланс: {{ this.userInfo.activeBalance }} бонусов
      </li>
    </ul>
  </div>
</template>

<script>
import router from '@/router';
import AuthorizationModal from "@/components/header/AuthorizationModal.vue";
import {authorizationMapper} from "@/store/modules/authorization";
import * as $ from 'jquery';

export default {
  name: 'SubHeader',
  components: {
    AuthorizationModal,
  },
  data() {
    return {
      isAuthorizationModalShown: false,
    }
  },
  computed: {
    ...authorizationMapper.mapGetters(['userInfo']),
  },
  methods: {
    ordersHandler() {
      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (this.$route.name === 'orders') {
          router.go(0);
        } else {
          router.push({ name: 'orders' });
        }
      }
    },
    closeAuthorizationModal(isShowModal) {
      this.isAuthorizationModalShown = isShowModal;
      const wrapTrash = $('.subheader');
      wrapTrash.removeClass('hide');
    },
  }
}
</script>

<style lang="scss" src="@/styles/SubHeader.scss">
</style>
