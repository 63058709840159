import VueRouter from 'vue-router';

import Content from '@/components/content/Content';
import GameInfo from '@/components/content/GameInfo';
import SectorInfo from '@/components/content/SectorInfo';
import SuccessOrderPay from '@/components/content/SuccessOrderPay';
import ErrorPage from '@/components/content/ErrorPage';
import PayOrderByEmailIdPage from '@/components/content/PayOrderByEmailIdPage';
import Abonements from '@/components/content/abonements/Abonements';
import AbonementsCalendarMatches from '@/components/content/abonements/AbonementsCalendarMatches.vue';
import Sponsors from '@/components/content/Sponsors';
import Orders from '@/components/content/orders/Orders';

const router =  new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: Content,
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: Content,
    },
    {
      path: '/payorder/byemailid',
      name: 'PayOrderByEmailIdPage',
      component: PayOrderByEmailIdPage,
    },
    {
      path: '/seasonticket',
      name: 'abonements',
      component: Abonements,
    },
    {
      path: '/sponsors',
      name: 'sponsors',
      component: Sponsors,
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders,
    },
    {
      path: '/seasonticket/multi/:calendarId',
      name: 'multiAbonement',
      component: AbonementsCalendarMatches,
    },
    {
      path: '/calendar/:calendarId',
      name: 'games',
      component: GameInfo,
    },
    {
      path: '/calendar/:calendarId/:sectorId',
      name: 'sector',
      component: SectorInfo,
    },
    {
      path: '/payment/:orderId/result',
      name: 'resultPay',
      component: SuccessOrderPay,
    },
    {
      path: '/error-page',
      name: 'errorPage',
      component: ErrorPage,
    },

    {
      path: '*',
      redirect: { 
        name: 'main',
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;
