<template>
  <div class="all">

    <div class="container" style="position: relative">
      <div class="content">
        <div class="game-info game-info__spinner" v-if="!gameInfo || !svg">
          <half-circle-spinner
            v-if="isContentLoading"
            :animation-duration="1000"
            :size="60"
            :color="'#FFF'"
          />
        </div>


        <div v-else class="tickets-system-local">

          <button
              v-if="this.userInfoId && reservedSeats.length"
              type="button"
              class="js-basket-shop basket-shop detail-page tiskets"
              :class="{'fixed': isScrollOver, 'loading': isTicketsPopupLoading}"
              :disabled="isTicketsPopupLoading"
              @click="showTicketsPopup()"
          >
            <half-circle-spinner
                v-if="isTicketsPopupLoading"
                class="tickets-popup-loader"
                :animation-duration="2000"
                :size="60"
                :color="'#0a275f'"
            />
            <div class="basket-shop__wrapper">
              <div class="basket-shop__selected-info">
                <span>Выбрано:</span>
                <span v-if="basketCalendar === 1"> {{ basketCalendar }} мероприятиe</span>
                <span v-if="basketCalendar >= 2 && basketCalendar <= 4"> {{ basketCalendar }} мероприятия</span>
                <span v-if="basketCalendar >= 5"> {{ basketCalendar }} мероприятий</span>
              </div>
              <div class="basket-shop__inner">
                <div class="basket-shop__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                          d="M19.99 6.59c.04-.32-.03-.6-.2-.83a.98.98 0 00-.7-.44L5.26 3.72l-.18-1.75c-.03-.21-.1-.4-.23-.57a.94.94 0 00-.47-.35l-3.05-1a1.04 1.04 0 00-.78.07 1 1 0 00-.52.63c-.07.3-.05.57.08.83.12.26.33.44.6.53l2.39.78.84 8.77-.56 3.13c-.04.33.04.63.25.9a1 1 0 00.8.38h12.88a1 1 0 00.73-.3c.2-.21.3-.47.3-.78 0-.3-.1-.56-.3-.77a1 1 0 00-.73-.3H5.67l.24-1.43h12.22c.25 0 .48-.08.68-.25.2-.17.3-.39.34-.65l.84-5zm-14 10.08c.45 0 .82.16 1.13.48.31.32.47.71.47 1.17 0 .46-.16.86-.47 1.19-.3.33-.68.49-1.12.49-.44 0-.82-.16-1.13-.5-.3-.32-.46-.72-.46-1.18A1.63 1.63 0 016 16.67zm10.55.48a1.5 1.5 0 00-1.12-.48c-.44 0-.82.16-1.13.48-.3.32-.46.71-.46 1.17 0 .46.16.86.46 1.19.31.33.69.49 1.13.49.44 0 .82-.16 1.12-.5.31-.32.47-.72.47-1.18 0-.46-.16-.85-.47-1.17z"
                          clip-rule="evenodd"></path>
                  </svg>
                </div>
                <div class="basket-shop__text basket-shop__text--margin">
                  <span class="tablet-hide">В&nbsp;корзине:</span>
                  <span>{{ basketQuantity }} 
                      <span v-if="basketQuantity === 1" class="tablet-hide">билет</span>
                      <span v-if="basketQuantity >= 2 && basketQuantity <= 4" class="tablet-hide">билета</span>
                      <span v-if="basketQuantity >= 5" class="tablet-hide">билетов</span>
                    </span>
                </div>
                <div class="basket-shop__text tablet-hide">
                  <span>на&nbsp;сумму:</span>
                  <span>{{ basketPrice }} ₽</span>
                </div>
              </div>
              <!-- <div class="basket-shop__info tablet-hide">
                  <span>Оформить заказ</span>
                  <svg>
                      <use xlink:href="#ic_arrow-right"></use>
                  </svg>
              </div> -->
            </div>
          </button>

          <div class="tickets-system-local__wrapper">

            <div class="tickets-system-local__top" v-if="gameInfo">
              <div class="game-info__button-back-wrapper">
                <!-- <router-link :to="{ name: 'main' }"> -->
                  <button class="game-info__button-back" @click="backToPage(gameInfo)">
                    <svg class="icon">
                      <use xlink:href="#ic_arrow-right"></use>
                    </svg>
                  </button>
                <!-- </router-link> -->
              </div>
              <GameMainInfo :gameInfo="gameInfo"/>

              <ul class="tickets-system-local__tabs-heading">
                <li class="tickets-system-local__tab-heading" :class="{active: showSchema === true}">
                  <button type="button" @click="showStadiumSchema">Схема</button>
                </li>
                <li class="tickets-system-local__tab-heading" :class="{active: showSchema === false}">
                  <button type="button" @click="showAvailableSeats">Список</button>
                </li>
              </ul>
            </div>

            <div class="tickets-system-local__main">

              <ul class="tickets-system-local__price-list">
                <li class="tickets-system-local__price-item"
                    v-for="legendPrice in legendPrices" :key="legendPrice.color">
                  <span v-if="legendPrice.color !== 'undefined'">{{legendPrice.max ? legendPrice.min + ' - ' + legendPrice.max : legendPrice.min}}&nbsp;₽</span>
                  <span v-if="legendPrice.color !== 'undefined'" class="tickets-system-local__price-item-line" :style="{backgroundColor: legendPrice.color}"></span>
                </li>
              </ul>

              <div lass="tickets-system-local__tabs-content" ref="container">

                <article class="tickets-system-local__tab-content show" v-if="showSchema">
                  <div class="tickets-system-local__tab-scheam-inner">
                    <SectorInfoLayer 
                      :data="sectorInfoLayerData"
                      :seasonIds="this.searchFilter"
                      v-on:mouseleave="hideTooltip"
                      v-on:mouseover="clearTooltipTimeout"
                      v-if="sectorInfoLayerData"
                    />

                    <div v-if="gameInfo && gameInfo.seatRootId != 8" class="tickets-system-local__info tickets-system-local__info--top" @click="openinfo(!loungeInfo)">
                                        <span class="tickets-system-local__info-heading">
                                            <svg>
                                                <use xlink:href="#ic_union-question"></use>
                                            </svg>
                                            <span class="text">VIP - ложи</span>
                                        </span>
                        <div v-if="loungeInfo" class="tickets-system-local__info-bubble" style="display: block;">
                          <!-- <div class="inner">
                            <p class="bold-text">Отдельную VIP-ложу можно забронировать на сайте <a href="https://vip.hcsochi.ru/" target="_blank">vip.hcsochi.ru</a> или через менеджера клуба - Наталью.
                              <br>Телефон: <a href="tel:+79284593939">8-928-450-39-39</a>, E-mail: <a href="mailro:tickets@hcsochi.ru">tickets@hcsochi.ru</a></p>
                          </div> -->
                          <div class="inner">
                            <p class="bold-text">По вопросам бронирования VIP-лож обращаться:
                              <br>
                                Менеджер Клуба - Наталья,
                              <!-- <br>
                                Телефон: <a href="tel:+79284593939">8 (928) 450-39-39</a>, -->
                              <br>
                                E-mail: <a href="mailro:tickets@hcsochi.ru">tickets@hcsochi.ru</a>
                            </p>
                          </div>
                        </div>
                    </div>

                    <div class="tickets-system-local__scheam" @click="goToSeats()" v-html="calendarSeatsSvg">
                    </div>
                  </div>
                </article>
                <article v-else class="tickets-system-local__tab-content js-tab-content show">
                  <div class="tickets-system-local__tab-list-inner">
                    <ul class="tickets-system-local__list">
                      <a class="tickets-system-local__list-item-head">
                        <span class="sector-head">ДОСТУПНЫЕ СЕКТОРЫ</span>
                        <span class="site-head">СВОБОДНЫЕ МЕСТА</span>
                        <span class="site-head" style="margin-right: 30px;">СТОИМОСТЬ</span>
                      </a>
                      <router-link :to="{ name: 'sector', params: { calendarId: calendarId, sectorId: sector.id } }"
                                   :data-id="sector.id" class="tickets-system-local__list-item"
                                   v-for="sector in availableSectorsList.availSectors" :key="sector.id">
                        <span class="sector">{{ sector.name }}</span>
                        <span class="site">{{ sector.freeSeats }} мест</span>
                        <span class="price">
                                {{
                            sector.availablePrices.length > 1 ?
                                `${sector.availablePrices[0]} - ${sector.availablePrices[sector.availablePrices.length - 1]}` :
                                sector.availablePrices[0]
                          }} ₽
                              </span>
                      </router-link>
                    </ul>
                    <SectorInfoLayer
                        :data="sectorInfoLayerData"
                        :seasonIds="this.searchFilter"
                        v-on:mouseleave="hideTooltip"
                        v-on:mouseover="clearTooltipTimeout"
                        v-if="sectorInfoLayerData"
                    />
                  </div>
                </article>
              </div>

            </div>
            <!-- <Goods/> -->
          </div>
        </div>
        <TicketsPopupOrder
            v-if="isTicketsPopupShown"
            @closeTicketsPopup="closeTicketsPopup"
            @stopTicketsPopupLoading="stopTicketsPopupLoading"
            :orderReservedSeats="reservedSeats"
            :principalInfo="principalInfo"
        />
      </div>
      <ErrorPopup
        v-if="isErrorPopupShown"
        @closeErrorPopup="closeErrorPopup"
        :errorMessage="errorMessage"
      />
    </div>
  </div>
</template>

<script>
import router from '@/router';

import {HalfCircleSpinner} from 'epic-spinners';
import GameMainInfo from './GameMainInfo.vue';
import SectorInfoLayer from "@/components/layers/SectorInfoLayer";
//import Goods from "./Goods.vue";
import TicketsPopupOrder from './TicketsPopupOrder.vue';
import ErrorPopup from './ErrorPopup.vue';

import {calendarMapper} from '@/store/modules/calendar';
import {analyticMapper} from '@/store/modules/analytic';
import {authorizationMapper} from "@/store/modules/authorization";

export default {
  name: 'GameInfo',
  data() {
    return {
      searchFilter: [],
      showSchema: true,
      sectorInfoLayerData: null,
      tooltipTimeoutId: null,
      svg: '',
      calendarId: null,
      sectorId: null,
      isTicketsPopupShown: false,
      loungeInfo: false,
      isErrorPopupShown: false,
      errorMessage: '',
      isContentLoading: false,
      isScrollOver: false,
      isTicketsPopupLoading: false,
      legendPrices: []
    }
  },
  components: {
    SectorInfoLayer,
    HalfCircleSpinner,
    GameMainInfo,
    TicketsPopupOrder,
    ErrorPopup,
    // Goods
  },
  async mounted() {
    try {
      this.isContentLoading = true,
      await this.getCalendarById(this.$route.params.calendarId);
      if (this.$route.query.seasonIds && this.gameInfo.algorithmSaleId === 540) {
        this.searchFilter = this.$route.query.seasonIds;
      }
      else {
        this.searchFilter = [];
      }

      await this.getAvailableSectorsList({calendarId: this.$route.params.calendarId, searchFilter: this.searchFilter});
    } catch(error) {
      if (error.status === 400) {
        router.push({ name: 'errorPage' });
      }
      if (error.status === 502) {
        this.errorMessage = "Ошибка связи с сервером.";
        this.isErrorPopupShown = true;
      }
    } finally {
      this.isContentLoading = false; 
    }
    await this.getReservedSeatsList();
    await this.getCalendarSchemeSvg(this.$route.params.calendarId);
    this.calendarId = this.$route.params.calendarId;

    const parseSvg = new Blob([this.calendarSeatsSvg], {type: 'image/svg+xml'});
    const url = URL.createObjectURL(parseSvg);
    this.svg = url;
    // Setup the event listener and execute it once in case we are already scrolled
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();

    const grouped = this.availableSectorsList.availSectors.reduce((accumulator, sector) => {
      const v = accumulator[sector.sectorColor] || [];
      v.push(sector);
      accumulator[sector.sectorColor] = v;
      return accumulator;
    }, {});

    const legendPrices = [];

    Object.keys(grouped)
        .filter(key => !!key)
        .forEach(key => {
          const prices = grouped[key].reduce((accumulator, sector) => {
            return accumulator.concat(sector.availablePrices);
          }, []);


          requestAnimationFrame(() => {
            grouped[key].forEach(sector => {
              const node = document.querySelector(`path[data-id="${sector.id}"]`);
              if (node) {
                node.style.fill = key;
              }
            })
          })

          const {min, max} = prices.reduce((accumulator, price) => {
            accumulator.min = Math.min(accumulator.min, price);
            accumulator.max = Math.max(accumulator.max, price);
            return accumulator;
          }, {min: Infinity, max: -Infinity});

          legendPrices.push({
            color: key,
            min: min,
            max: min === max ? null : max
          });
        });

    this.legendPrices = legendPrices;
    this.legendPrices = this.legendPrices.sort((a,b) => a.min - b.min);

    requestAnimationFrame(() => {
      this.hideInactiveSectors();

      this.$refs.container.addEventListener('mousemove', (event) => {
        const sectorEl = event.target.closest('[data-id]');
        if (!sectorEl) {
          this.sectorId = null;
          const schemeContainer = event.target.closest('.game-info__schema');
          if (schemeContainer) {
            this.hideTooltip();
          }
          return;
        }
        const sectorId = +sectorEl.getAttribute('data-id');
        this.sectorId = sectorId;
        const sectorData = this.availableSectorsList.availSectors.find(s => s.id === sectorId);
        if (!sectorData) {
          this.hideTooltip();
          this.sectorId = null;
          return;
        }
        const containerBbox = this.$refs.container.getBoundingClientRect();
        const bbox = sectorEl.getBoundingClientRect();
        this.showTooltip({
          top: containerBbox.height - (bbox.top - containerBbox.top) - bbox.height / 2,
          left: bbox.left - containerBbox.left + bbox.width / 2,
          name: sectorData.name,
          sectorId: sectorId,
          calendarId: this.$route.params.calendarId,
          freeSeats: sectorData.freeSeats,
          availablePrices: sectorData.availablePrices
        });

      })
    });
  },
  methods: {
    ...calendarMapper.mapActions(['getCalendarSchemeSvg', 'getAvailableSectorsList', 'getAvailableSeatList', 'getCalendarById', 'getReservedSeatsList']),
    ...analyticMapper.mapActions(['addToBasket', 'removeFromBasket']),
    stopTicketsPopupLoading(isLoading) {
      this.isTicketsPopupLoading = isLoading;
      this.loungeInfo = false;
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
      this.loungeInfo = false;
    },
    showAvailableSeats() {
      this.showSchema = false;
      this.loungeInfo = false;
    },
    showStadiumSchema() {
      this.showSchema = true;
      this.loungeInfo = false;
      requestAnimationFrame(() => {
        this.hideInactiveSectors();
      });
    },
    showTooltip(data) {
      this.loungeInfo = false;
      this.clearTooltipTimeout();
      this.sectorInfoLayerData = data;
    },
    hideTooltip() {
      this.loungeInfo = false;
      if (this.tooltipTimeoutId !== null) {
        return;
      }
      this.tooltipTimeoutId = setTimeout(() => {
        this.sectorInfoLayerData = null;
      }, 250);
    },
    clearTooltipTimeout() {
      this.loungeInfo = false;
      if (this.tooltipTimeoutId !== null) {
        clearTimeout(this.tooltipTimeoutId);
        this.tooltipTimeoutId = null;
      }
    },
    hideInactiveSectors() {
      const availableIds = this.availableSectorsList.availSectors.map(v => v.id);
      this.$refs.container.querySelectorAll('[data-id]').forEach(el => {
        const dataId = +el.getAttribute('data-id');
        if (!availableIds.includes(dataId)) {
          el.classList.add('disabled');
        }
      });
    },
    showTicketsPopup() {
      this.loungeInfo = false;
      this.isTicketsPopupShown = true;

      if (!this.bonuses) {
        this.isTicketsPopupLoading = true;
      }
    },

    goToSeats() {
      this.loungeInfo = false;
      if(this.sectorId && this.sectorId > 0) {
        router.push({ name: 'sector', params: { calendarId: this.calendarId, sectorId: this.sectorId}, query: { seasonIds: this.searchFilter} });
      }
    },
    
    backToPage(gameInfo) {
      this.loungeInfo = false;
      if (gameInfo.algorithmSaleId === 520 || gameInfo.algorithmSaleId === 530) {
        router.push({ name: 'abonements'});
      } else {
        router.push({ name: 'main'});
      }
    },

    closeTicketsPopup(isShowTicketsPopup) {
      this.loungeInfo = false;
      this.isTicketsPopupShown = isShowTicketsPopup;
    },
    async schemeClick(event) {
      this.loungeInfo = false;
      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        return this.isAuthorizationModalShown = true;
      }

      if (event.target.className.includes('seat available')) {
        const reserveInfo = {
          "quantity": 1,
          "seasonIds": this.searchFilter,
          "seatId": event.target.id,
          "categoryId": 1,
        }

        await this.reserveSeat({calendarId: this.gameInfo.id, reserveInfo});
        const node = document.getElementById(reserveInfo.seatId);
        if (node) {
          node.classList.add('reserved');
          node.classList.remove('available');
        }
        this.addToBasket(this.reservedSeats[this.reservedSeats.length-1]);
      } else if (event.target.className.includes('seat reserved')) {
        const unreserveInfo = {
          quantity: 1,
          seatId: event.target.id
        }
        await this.unreserveSeat({calendarId: this.gameInfo.id, unreserveInfo});
        const node = document.getElementById(unreserveInfo.seatId);
        if (node) {
          node.classList.add('available');
          node.classList.remove('reserved');
        }
        this.removeFromBasket({calendar: this.gameInfo, unreserveInfo});
      }
    },
    scrollHandler() {
      this.loungeInfo = false;
      this.isScrollOver = window.pageYOffset > 80;
    },
    openinfo(loungeInfo) {
      this.loungeInfo = loungeInfo;
    }
  },
  beforeDestroy() {
    this.loungeInfo = false;
    window.removeEventListener('scroll', this.scrollHandler);
  },
  computed: {
    ...calendarMapper.mapGetters(['calendarSeatsSvg', 'availableSectorsList', 'gameInfo', 'reservedSeats', 'bonuses']),
    ...authorizationMapper.mapGetters(['userInfo', 'principalInfo']),
    userInfoId() {
      return this.userInfo?.userId;
    },
    basketQuantity() {
      return this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {quantity}) => {
        return accumulator + quantity;
      }, 0) : 0
    },
    basketPrice() {
      return this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {price}) => {
        return accumulator + Number(price);
      }, 0) : 0
    },
    basketCalendar() {
      return Object.keys(
          this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {calendarId}) => {
            accumulator[calendarId] = 1;
            return accumulator;
          }, {}) : {}).length;
    }
  },
}
</script>

<style lang="scss" src="@/styles/GameInfo.scss">
</style>

<style scoped lang="scss">
.tickets-popup-loader {
  position: absolute;
  left: 45%;
  top: 25%;
  z-index: 10;

  @media (max-width: 1279px) {
    display: none;
  }
}
</style>
